import React, {useState} from 'react';
import UserIndexView from './UserIndexView';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { logout } from '../../reducers/app';
import useInterval from '../../utils/hooks/useInterval';
import {clearMeetingData, setActiveMeeting, setQueueList} from '../../reducers/meeting';

const UserIndex = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useInterval(() => {
      axios.get('/api/meeting-action.php', { params: { action: 'get-user-queue' } })
          .then(response => {
              if (response.data.success) {
                  dispatch(setQueueList(response.data.queueList));
                  dispatch(setActiveMeeting(response.data.activeMeeting));
              }
          });
      },5000, true
  );

  const doLogout = () => {
    axios.get('/api/guest-action.php', { params: { action: 'logout' } })
        .then(response => {
          if (response.data.success) {
            dispatch(clearMeetingData());
            dispatch(logout());
            history.push('/userLogin')
          }
        });
  };

  return (
    <UserIndexView doLogout={doLogout} />
  )
};

export default UserIndex;
