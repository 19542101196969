import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userType: '',
  userInfo: null,
  branchList: [],
  cityList: [],
  classLevelList: [],
  config: {
    sms: {
      username: null,
      password: null,
      originator: null,
      originators: [],
      serviceProvider: null,
      credit: 0,
      mobile: null
    }
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loginAsUser: (state, action) => {
      state.userType = 'user';
      state.userInfo = action.payload;
    },

    loginAsCustomer: (state, action) => {
      state.userType = 'customer';
      state.userInfo = action.payload;
    },

    loginAsGuest: (state) => {
      state.userType = 'guest'
    },

    logout: (state) => {
      state.userType = 'guest';
      state.userInfo = null;
      state.config = initialState.config;
      /* const branchList = {...state.branchList};
      const cityList = {...state.cityList};
      state = {...initialState, branchList, cityList}; */
    },

    setBranchList: (state, action) => {
      state.branchList = action.payload;
    },

    setCityList: (state, action) => {
      state.cityList = action.payload;
    },

    setClassLevelList: (state, action) => {
      state.classLevelList = action.payload;
    },

    setActiveMeetingUrl: (state, action) => {
      state.userInfo.meetingUrl = action.payload;
    },

    setSMSConfig: (state, action) => {
      state.config.sms = {...state.config.sms, ...action.payload};
      if (action.payload.originators) {
        state.config.sms.originators = action.payload.originators.map((item) => {return {label: item, value: item}});
      }
    },

    setSMSOriginators: (state, action) => {
      state.config.sms.originators = action.payload.originators.map((item) => {return {label: item, value: item}});
      state.config.sms.credit = action.payload.credit;
    }
  },
});

export const {
  loginAsUser,
  loginAsCustomer,
  loginAsGuest,
  logout,
  setBranchList,
  setActiveMeetingUrl,
  setClassLevelList,
  setCityList,
  setSMSConfig,
  setSMSOriginators } = appSlice.actions;

export default appSlice.reducer
