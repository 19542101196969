import axios from 'axios';
//import { appLogout } from '../Store/reducers/app/actions';
import message from 'antd/lib/message';

const showMessageForResponse = response => {
  if (response.data.msg) {
    const variant =
      response.data.msgType ||
      (response.data.success ? 'success' : 'error');
    if (variant === 'success') {
      message.success(response.data.msg);
    } else if (variant === 'error') {
      message.error(response.data.msg);
    } else {
      message.info(response.data.msg);
    }
    return response;
  }
};

const setupAxios = store => {
  axios.interceptors.response.use(
    response => {
      showMessageForResponse(response);
      return response;
    },
    error => {
      if (error.response.status === 403) {
        // store.dispatch(appLogout());
      }
      Promise.reject(error);
      showMessageForResponse(error.response);
      return error.response;
    },
  );
  axios.hasInterceptor = true;
};

export default setupAxios;
