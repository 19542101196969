import React, {useState} from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Layout from 'antd/lib/layout';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './style.css';

const { Content, Footer } = Layout;

const LoginView = ({ doLogin, isLoadingPending }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    return (
        <Layout>
            <Content style={{ width: '100vw', height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Card
                    bordered={false}
                    title="MeetDesk Giriş"
                    className="box-shadow"
                    style={{ width: 400 }}
                    cover={<div style={{ textAlign: 'center' }}>
                        <img src="/assets/images/applogo.png" style={{width: 200, margin: 20}} />
                    </div>}
                >
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={() => {}}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Lütfen kullanıcı adınızı giriniz!' }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Kullanıcı Adı"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Lütfen şifrenizi giriniz!' }]}
                        >
                            <Input
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Şifre"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%' }}
                                loading={isLoadingPending}
                                onClick={() => doLogin(username, password)}
                            >
                                Giriş
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                <Footer style={{ textAlign: 'center', bottom: 0 }}>MeetDesk ©2021 Created by Akbim</Footer>
            </Content>
        </Layout>
    );
};

export default LoginView;
