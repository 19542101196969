import React from 'react';
import ActiveMeetingPanelView from './ActiveMeetingPanelView';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import { message } from 'antd';
import {setActiveMeetingAsStarted, setActiveMeeting} from '../../../../reducers/meeting';
import {setActiveMeetingUrl} from '../../../../reducers/app';


const ActiveMeetingPanel = ({}) => {
  const dispatch = useDispatch();
  const activeMeeting = useSelector(state => state.meeting.activeMeeting);
  const activeMeetingUrl = useSelector(state => state.app.userInfo.meetingUrl);

  const startMeeting = () => {
    if (activeMeeting.state === 2) {
      window.open(activeMeetingUrl, '_blank');
      return;
    }
    axios.get('/api/meeting-action.php', { params: { action: 'start-meeting', id: activeMeeting.id } })
        .then(response => {
          if (response.data.success) {
            dispatch(setActiveMeetingAsStarted());
            window.open(activeMeetingUrl, '_blank');
          }
        });
  };

  const endMeeting = () => {
    axios.get('/api/meeting-action.php', { params: { action: 'end-meeting', id: activeMeeting.id } })
        .then(response => {
          if (response.data.success) {
            dispatch(setActiveMeeting(null));
          }
        });
  };

  const updateMeetingUrl = (url) => {
    const re = new RegExp('^(http|https)://');
    if (!re.test(url)) {
      message.warning('Toplantı linki "http" veya "https" ile başlamalı');
      return;
    }
      axios.get('/api/meeting-action.php', { params: { action: 'set-meeting-url', url } })
          .then(response => {
              if (response.data.success) {
                  dispatch(setActiveMeetingUrl(url));
              }
          });
  };

  return (
    <ActiveMeetingPanelView
        activeMeeting={activeMeeting}
        startMeeting={startMeeting}
        endMeeting={endMeeting}
        activeMeetingUrl={activeMeetingUrl}
        updateMeetingUrl={updateMeetingUrl}
    />
  )
};

export default ActiveMeetingPanel;
