import React, { useEffect, useState } from 'react';
import ConfigModalView from './ConfigModalView';
import axios from 'axios';
import { message } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {setSMSConfig, setSMSOriginators} from "../../../../reducers/app";

const ConfigModal = ({ onHideModal }) => {
  const dispatch = useDispatch();
  const config = useSelector(state => state.app.config.sms);
  const [isVerified, setIsVerified] = useState(false);

  const onVerify = form => {
    const { username, password, serviceProvider } = form.getFieldsValue();
    if (!username || !password || serviceProvider === null) {
      message.warning('Lütfen gerekli alanları doldurunuz.');
      return;
    }
    axios.get('/api/sms-action.php', { params: { action: 'get-originators', username, password, serviceProvider } })
      .then(response => {
        if (response.data.success) {
          const { credit, originators } = response.data;
          dispatch(setSMSOriginators({credit, originators}));
          setIsVerified(true);
        }
      });
  };

  const onSaveConfig = values => {
    const { username, password, serviceProvider, originator, mobile } = values;
    if (!username || !password || !originator || serviceProvider === null) {
      message.warning('Lütfen gerekli alanları doldurunuz.');
      return;
    }
    axios.post('/api/sms-action.php', { action: 'save-config', username, password, serviceProvider, originator, mobile })
      .then(response => {
        if (response.data.success) {
          dispatch(setSMSConfig({username, password, serviceProvider, originator, mobile}));
          onHideModal();
        }
      });
  };

  useEffect(() => {
    axios.get('/api/sms-action.php', { params: { action: 'get-config' } })
      .then(response => {
        if (response.data.success) {
          const { config } = response.data;
          dispatch(setSMSConfig(config));
        }
      });
  }, []);

  return (
    <ConfigModalView
      onHideModal={onHideModal}
      config={config}
      onVerify={onVerify}
      isVerified={isVerified}
      onFinish={onSaveConfig}
    />
  )
};

export default ConfigModal;
