import React from 'react';
import LoginView from './LoginView';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginAsUser } from '../../reducers/app';

const Login = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const doLogin = (username, password) => {
    if (!username|| username.length === 0 || !password || password.length === 0) {
      return false;
    }
    axios.get('/api/guest-action.php', {params: {
      action: 'try-login', username, password
      }}).then(response => {
       if (response.data.success) {
         dispatch(loginAsUser(response.data.user));
         setTimeout(() => {
           history.push('/');
         }, 50);
       }
    });
  };

  return (
    <LoginView doLogin={doLogin} />
  )
};

export default Login;
