import React, { useState } from 'react';
import { Card, Table, Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './style.css';

const { Text } = Typography;

const QueuePanelView = ({ queueList, activeMeeting, pickFromQueue, selectedRow, setSelectedRow }) => {
    const columns = [
        {
            title: 'Sıra',
            dataIndex: 'rank',
            width: 60,
            render: (text, record, index) => <Text>{index+1}</Text>,
        },
        {
            title: 'Adı Soyadı',
            dataIndex: 'nameSurname',
        },
        {
            title: 'Mobil',
            dataIndex: 'mobile',
        },
        {
            title: 'Seviye',
            dataIndex: 'classLevel',
        },
        {
            title: 'Tarih',
            dataIndex: 'createDate',
            render: (text, record, index) => {const d = new Date(record.createDate); return (<Text>{d.toLocaleDateString('tr')} {d.toLocaleTimeString('tr')}</Text>)},
        },
    ];

    return (
        <Card
            bordered={false}
            title="Bekleyen Görüşmeler"
            style={{ maxWidth: 700, minWidth: 500, textAlign: 'center',
                display: 'flex', flexDirection: 'column', justifyContent: 'stretch'}}
            bodyStyle={{ padding: 0, flex: 1, display: 'flex', flexDirection: 'column' }}
        >
            <Table
                columns={columns}
                dataSource={queueList}
                rowKey="customerId"
                size="small"
                className="queue-table"
                locale={{ emptyText: 'Görüşme sırası boş' }}
                rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys, selectedRows) => {
                        if (selectedRows && selectedRows.length > 0) {
                            setSelectedRow(selectedRows[0])
                        }
                    },
                }}
            />
            <div style={{ padding: 20 }}>
                <Button
                    type="success"
                    style={{ width: '300px', marginTop: 50 }}
                    size="large"
                    shape="round"
                    onClick={pickFromQueue}
                    disabled={activeMeeting || queueList.length === 0}
                >
                    {selectedRow ? 'Seçili Olanı Al' : 'Sıradan Al'}
                    <ArrowRightOutlined />
                </Button>
            </div>
        </Card>
    );
};

export default QueuePanelView;
