import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import CustomerIndexView from './CustomerIndexView';
import useInterval from '../../utils/hooks/useInterval';
import {logout} from '../../reducers/app';
import { useDispatch } from 'react-redux';

const meetingStates = ['onQueue', 'ready', 'meeting', 'end'];
const CustomerIndex = ({}) => {
  const [callState, setCallState] = useState('');
  const [rank, setRank] = useState('');
  const [isQueued, setIsQueued] = useState(false);
  const [lastMeeting, setLastMeeting] = useState(null);
  const [hasActiveUser, setHasActiveUser] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useInterval(() => {
    if (callState === 'end') {
      return;
    }
    axios.get('/api/meeting-action.php', { params: { action: 'get-meeting-state' } })
        .then(response => {
          if (response.data.success) {
            const { lastMeeting, rank, hasActiveUser} = response.data;
            setLastMeeting(lastMeeting);
            setCallState(!lastMeeting ? 'none' : meetingStates[lastMeeting.state]);
            setRank(rank);
            setHasActiveUser(hasActiveUser);
            if (!lastMeeting) {
              setIsQueued(false);
            }
          }
        });
  },5000, true);

  const addToQueue = () => {
    setIsQueued(true);
    axios.post('/api/meeting-action.php', { action: 'add-to-queue' })
        .then(response => setCallState(meetingStates[0]));
  };

  const startMeeting = () => {
    window.open(lastMeeting.meetingUrl);
  };

  const doLogout = () => {
    axios.get('/api/guest-action.php', { params: { action: 'logout' } })
        .then(response => {
          if (response.data.success) {
            dispatch(logout());
            history.push('/')
          }
        });
  };

  return (
    <CustomerIndexView
        callState={callState}
        rank={rank}
        isQueued={isQueued}
        addToQueue={addToQueue}
        startMeeting={startMeeting}
        doLogout={doLogout}
        hasActiveUser={hasActiveUser}
    />
  )
};

export default CustomerIndex;
