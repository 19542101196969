import { useEffect, useState, useRef } from 'react';

const useInterval = (callback, interval, runOnLoad = false, extraDependencies = []) => {
  const savedCallback = useRef();

  const [intervalId, setIntervalId] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(0);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (intervalId > 0) {
      clearInterval(intervalId);
    }
    if (interval) {
      let id = setInterval(() => {
        setLastUpdate((new Date).getTime());
      }, interval);
      setIntervalId(id);
    }

    return () => {
      if (intervalId > 0) {
        clearInterval(intervalId);
      }
    }
  }, [interval, ...extraDependencies]);

  useEffect(() => {
    if (runOnLoad || lastUpdate > 0) {
      savedCallback.current();
    }
  }, [lastUpdate, ...extraDependencies]);
};

export default useInterval;
