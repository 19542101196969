import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import { useSelector, useDispatch } from 'react-redux';
import { loginAsCustomer, loginAsUser } from './reducers/app';
import { HashRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom';
import CustomerRegister from './screens/CustomerRegister';
import Loading from './screens/Loading';
import Login from './screens/Login';
import CustomerIndex from './screens/CustomerIndex';
import UserIndex from './screens/UserIndex';

function UserLoginPage() {
  return <h3>UserLoginPage</h3>;
}

const ConditionalRoute = ({ children, condition, redirectTo, ...rest }) => {
 return (
   <Route
     {...rest}
     render={({ location }) =>
       condition ? (
         children
       ) : (
         <Redirect
           to={{
             pathname: redirectTo,
             state: { from: location }
           }}
         />
       )
     }
   />
 )
};

function App() {
  const userType = useSelector(state => state.app.userType);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (userType === 'customer') {
              return <CustomerIndex />
            } else if (userType === 'user') {
              return <UserIndex />
            } else if (userType === 'guest') {
                return <CustomerRegister />
            } else {
              return <Loading />
            }
          }}
        />
        <Route exact path="/userLogin">
          <Login />
        </Route>
        <ConditionalRoute exact condition={userType === 'user'} path="/userIndex" redirectTo="/">
          <UserIndex />
        </ConditionalRoute>
        <Redirect to="/"/>
      </Switch>
    </Router>
  );
}

export default App;
