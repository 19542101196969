import React from 'react';
import {Layout, Card, Typography, Alert, Row, Col} from 'antd';
import Button from 'antd/lib/button';
import {LogoutOutlined, UserSwitchOutlined, VideoCameraOutlined} from '@ant-design/icons';
import './style.css';
import {useSelector} from 'react-redux';

const { Content, Footer, Header } = Layout;
const { Title } = Typography;
const CustomerIndex = ({ callState, rank, isQueued, addToQueue, startMeeting, doLogout, hasActiveUser }) => {
    // callState: none, onQueue, ready, meeting, end
  const { userInfo } = useSelector(state => state.app);
  return (
      <Layout>
          <Header>
              <Row>
                <Col style={{width: 100}} />
                  <Col flex="1" style={{ textAlign: 'center', marginTop: 14 }}>
                      <Title style={{ color: '#fff' }} level={3}>Merhaba, {userInfo.nameSurname}</Title>
                  </Col>
                  <Col style={{ textAlign: 'right', width: 100 }}>
                      <Button
                          type="danger"
                          shape="round"
                          onClick={doLogout}
                      >
                          <LogoutOutlined />
                          Çıkış
                      </Button>
                  </Col>
              </Row>
          </Header>
          <Content style={{ width: '100vw', height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
          }}>
              <Card
                  bordered={false}
                  title="Online Kayıt Görüşme Bekleme Salonu"
                  className="box-shadow"
                  style={{ maxWidth: 800, minWidth: 600, textAlign: 'center' }}
              >
                  {rank && rank > 0 && (callState === 'none' || callState === 'onQueue') &&
                      <div>
                          <Title type="warning" code level={3}>SAYIN {userInfo.nameSurname}</Title>
                          <Title type="warning" code level={3}>KAYIT GÖRÜŞMESİ İÇİN {rank}. SIRADASINIZ.</Title>
                      </div>
                  }
                  {!hasActiveUser &&
                    <Alert
                      style={{ marginTop: 50 }}
                      message="Şuan aktif bir kullanıcı olmadığından hemen yanıt alamayabilirsiniz ancak en kısa sürede dönüş yapılacaktır."
                      type="warning" showIcon
                    />
                  }
                  {(callState === 'onQueue' || callState === 'ready') &&
                      <Alert
                          style={{ textAlign: 'left', marginTop: 100 }}
                          message="Bilgi Notu"
                          description="Canlı görüşmeniz hazır olduğunda aşağıdaki buton aktif olacak."
                          type="info"
                          showIcon
                      />
                  }
                  {callState === 'end' &&
                  <Alert
                      style={{ textAlign: 'left', marginTop: 50, marginBottom: 50}}
                      message="Bilgi Notu"
                      description="Görüşmeniz sonlandı."
                      type="info"
                      showIcon
                  />
                  }
                  {callState === 'meeting' &&
                  <Alert
                      style={{ marginTop: 50, marginBottom: 50}}
                      className="blink"
                      message="Görüşmeniz başladı."
                      description="Canlı görüşmeniz devam ediyor..."
                      type="warning"
                      showIcon
                  />
                  }
                  {callState === 'none' &&
                      <Button
                          type="info"
                          style={{ width: '300px', marginTop: 100 }}
                          size="large"
                          shape="round"
                          disabled={isQueued}
                          onClick={addToQueue}
                      >
                          <UserSwitchOutlined />
                          Görüşme Talebi Gönder
                      </Button>
                  }
                  {(callState === 'onQueue' || callState === 'ready' || callState === 'meeting') &&
                      <Button
                          type="success"
                          disabled={callState !== 'meeting'}
                          style={{ width: '300px', marginTop: 50 }}
                          size="large"
                          shape="round"
                          onClick={startMeeting}
                      >
                          <VideoCameraOutlined />
                          Canlı Görüşmeye Git
                      </Button>
                  }
              </Card>
              <Footer style={{ textAlign: 'center', bottom: 0 }}>MeetDesk ©2021 Created by Akbim</Footer>
          </Content>
      </Layout>
  );
};

export default CustomerIndex;
