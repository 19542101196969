import { createSlice } from '@reduxjs/toolkit'

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState: {
    queueList: [],
    activeMeeting: null
  },
  reducers: {
    setQueueList: (state, action) => {
      state.queueList = action.payload;
    },
    setActiveMeeting: (state, action) => {
      state.activeMeeting = action.payload;
      if (action.payload) {
        state.queueList = state.queueList.filter(item => item.id !== action.payload.id);
      }
    },
    setActiveMeetingAsStarted: (state) => {
      state.activeMeeting.state = 2;
    },
    clearMeetingData: (state) => {
      state.queueList = [];
      state.activeMeeting = null;
    }
  },
});

export const { setQueueList, setActiveMeeting, setActiveMeetingAsStarted, clearMeetingData } = meetingSlice.actions;

export default meetingSlice.reducer;
