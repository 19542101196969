import React, {useState} from 'react';
import { Layout, Typography, Divider, Row, Col, Space } from 'antd';
import Button from 'antd/lib/button';
import QueuePanel from './Components/QueuePanel';
import ActiveMeetingPanel from './Components/ActiveMeetingPanel';
import {useSelector} from 'react-redux';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import ConfigModal from "./Components/ConfigModal";
const { Content, Footer, Header } = Layout;
const { Title } = Typography;

const UserIndexView = ({ doLogout }) => {
    const [configModalVisible, setConfigModalVisible] = useState(false);
    const { userInfo } = useSelector(state => state.app);

    return (
        <Layout>
            {configModalVisible &&
                <ConfigModal onHideModal={() => setConfigModalVisible(false)} />
            }
            <Header>
                <Row>
                    <Col style={{width: 100}} />
                    <Col flex="1" style={{ textAlign: 'center', marginTop: 14 }}>
                        <Title style={{ color: '#fff' }} level={3}>Merhaba, {userInfo.userNameSurname}</Title>
                    </Col>
                    <Col style={{ textAlign: 'right', width: 100 }}>
                        <Space>
                            <Button
                              shape="round"
                              onClick={() => setConfigModalVisible(true)}
                            >
                                <SettingOutlined />
                            </Button>
                            <Button
                              type="danger"
                              shape="round"
                              onClick={doLogout}
                            >
                                <LogoutOutlined />
                                Çıkış
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Header>
            <Content style={{ width: '100vw', height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <div style={{ display: 'flex', height: '100vh' }}>
                    <QueuePanel />
                    <Divider type="vertical" />
                    <ActiveMeetingPanel />
                </div>
                <Footer style={{ textAlign: 'center', bottom: 0 }}>MeetDesk ©2021 Created by Akbim</Footer>
            </Content>
        </Layout>
  );
};

export default UserIndexView;
