import React, { useEffect } from 'react';
import {Select, Card, Form, Input, Button, Layout} from 'antd';
import {UserOutlined, MailOutlined, LoginOutlined } from '@ant-design/icons';
import './style.css';
import {Link} from "react-router-dom";

const { Content, Footer } = Layout;
const { Option } = Select;

const CustomerRegisterView = ({ onFinish, cityList, branchList, classLevelList, isRegisterPending, branchId, cityCode, setCityCode}) => {
  const [form] = Form.useForm();
  const submitForm = () => form.submit();

  useEffect(() => {
    if (branchId && branchList.length > 0) {
      branchList.forEach(item => {
        if (item.value === branchId) {
          form.setFieldsValue({ branchId, cityCode})
        }
      });
    }
  }, [branchList, branchId, cityCode]);

  const onCityChange = (value) => {
    setCityCode(value);
  };

  return (
    <Layout>
      <Content style={{ width: '100vw', height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Card
          bordered={false}
          title="Canlı görüşme kayıt formu"
          className="box-shadow customer-form"
          cover={<div style={{ textAlign: 'center' }}>
            <img src="/assets/images/applogo.png" style={{width: 200, margin: 20}} />
          </div>}
        >
          <Form
            name="customer-register"
            form={form}
            className="login-form"
            initialValues={{ prefix: '90' }}
            onFinish={onFinish}
          >
            <Form.Item name="cityCode" rules={[{ required: true, message: 'İl seçmediniz.' }]}>
              <Select showSearch options={cityList} placeholder="Kurumun bulunduğu il"
                      filterOption={(input, option) => option.label.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
                      onChange={onCityChange}
              />
            </Form.Item>
            <Form.Item name="branchId" rules={[{ required: true, message: 'Kurum seçmediniz.' }]}>
              <Select showSearch options={branchList} placeholder="Görüşmek istediğiniz kurum"
                      filterOption={(input, option) => option.label.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
              />
            </Form.Item>
            <Form.Item name="nameSurname" rules={[
              { required: true, message: 'Adınızı soyadınızı girmediniz.' },
              { min: 2, message: 'En az 2 karakter girmelisiniz.' },
            ]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Adınız Soyadınız" />
            </Form.Item>
            <Form.Item
              name="mobile"
              rules={[
                { required: true, message: 'Telefon numaranızı girmediniz.' },
                { min: 10, max: 10, message: 'Telefon numarası başında 0 olmadan 10 karakter olmalı.' },
                {
                  required: true,
                  pattern: /^\d+$/g,
                  message: 'Lütfen geçerli bir telefon numarası giriniz.'
                }

              ]}
            >
              <Input
                addonBefore={
                  <Form.Item name="prefix" noStyle>
                    <Select
                      style={{
                        width: 70,
                      }}
                    >
                      <Option value="90">+90</Option>
                    </Select>
                  </Form.Item>
                }
                placeholder="Telefon numaranız"
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Lütfen geçerli bir E-Posta adresi giriniz',
                },
                {
                  required: true,
                  message: 'E-Posta adresinizi girmediniz.',
                },
              ]}
            >
              <Input placeholder="E-Posta adresi" prefix={<MailOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item name="classLevel" rules={[{ required: true, message: 'Sınıf seviyesi seçmediniz.' }]}>
              <Select showSearch options={classLevelList} placeholder="Öğrencinizin sınıf seviyesi"
                      filterOption={(input, option) => option.label.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex' }}>
                <Button
                  type="dashed"
                  htmlType="reset"
                  style={{ width: 80, marginRight: 10 }}
                  //loading={isLoadingPending}
                  onClick={() => form.resetFields()}
                  disabled={isRegisterPending}
                >
                  Sıfırla
                </Button>
                <Button
                  type="success"
                  htmlType="submit"
                  style={{ flex: 1 }}
                  //loading={isLoadingPending}
                  onClick={submitForm}
                  disabled={isRegisterPending}
                >
                  <LoginOutlined className="site-form-item-icon" />
                  Görüşme için kayıt ol
                </Button>
              </div>

            </Form.Item>
          </Form>
        </Card>
        <Footer style={{ textAlign: 'center', bottom: 0 }}><Link to="/userLogin">MeetDesk ©2021 Created by Akbim</Link></Footer>
      </Content>
    </Layout>
  );
};

export default CustomerRegisterView;
