import React from 'react';
import { Spin, Layout, Typography, Card } from 'antd';
const { Content } = Layout;
const { Text } = Typography;

const LoadingView = () => {
  return (
    <Layout>
      <Content style={{ width: '100vw', height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Spin tip="Yükleniyor..." />
      </Content>
    </Layout>
  );
};

export default LoadingView;
