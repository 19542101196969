import React, {useState} from 'react';
import QueuePanelView from './QueuePanelView';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {setActiveMeeting} from '../../../../reducers/meeting';


const QueuePanel = ({ }) => {
    const {queueList, activeMeeting} = useSelector(state => state.meeting);
    const [selectedRow, setSelectedRow] = useState(null);
    const dispatch = useDispatch();
    const pickFromQueue = () => {
        const firstMeeting = selectedRow ? selectedRow : queueList[0];
        axios.get('/api/meeting-action.php', { params: { action: 'pick-from-queue', id: firstMeeting.id } })
          .then(response => {
              if (response.data.success) {
                  setSelectedRow(null);
                  dispatch(setActiveMeeting({...firstMeeting, state: 1}));
              }
          });
    };

    return (
      <QueuePanelView
        queueList={queueList}
        activeMeeting={activeMeeting}
        pickFromQueue={pickFromQueue}
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
      />
    )
};

export default QueuePanel;
