import React, { useEffect } from 'react';
import LoadingView from './LoadingView';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loginAsUser, loginAsCustomer, loginAsGuest } from '../../reducers/app';

const Loading = ({}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get('/api/guest-action.php', { params: { action: 'get-login-state' } })
      .then(response => {
        if (response.data.success) {
          const { userType, user } = response.data;
          if (userType === 'guest') {
            dispatch(loginAsGuest());
          } else if (userType === 'user') {
            dispatch(loginAsUser(user));
          } else if (userType === 'customer') {
            dispatch(loginAsCustomer(user));
          }
        }
      });
  }, []);

  return (
    <LoadingView />
  )
};

export default Loading;
