import React, {useEffect} from 'react';
import {Select, Form, Modal, Input, Button} from 'antd';
import { VerifiedOutlined } from '@ant-design/icons';

const { Option } = Select;

const ConfigModalView = ({ config, onHideModal, onVerify, isVerified, onFinish, userMobile }) => {
  const [form] = Form.useForm();
  const submitForm = () => form.submit();

  useEffect(() => {
    form.setFieldsValue({...config});
  }, [config.username]);

  return (
    <Modal
      title="Uygulama Ayarları"
      visible
      cancelText="Vazgeç"
      okButtonProps={{ type: 'success' }}
      okText="Kaydet"
      onCancel={onHideModal}
      onOk={submitForm}
      maskClosable={false}

    >
      <h3 style={{ textAlign: 'center' }}>SMS Ayarları</h3>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        initialValues={{
          serviceProvider: config.serviceProvider,
          username: config.username,
          password: config.password,
          originator: config.originator,
          mobile: config.mobile,
          prefix: '90'
        }}
      >
        <Form.Item
          label="Sağlayıcı"
          name="serviceProvider"
          rules={[{ required: true, message: 'Lütfen sağlayıcı seçiniz.' }]}
        >
          <Select disabled={isVerified}>
            <Select.Option value={0}>Mutlucell</Select.Option>
            <Select.Option value={1}>Modexi</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="SMS Kullanıcı Adı"
          name="username"
          rules={[{ required: true, message: 'Lütfen kullanıcı adınızı giriniz!' }]}
        >
          <Input disabled={isVerified} />
        </Form.Item>

        <Form.Item
          label="SMS Şifresi"
          name="password"
          rules={[{ required: true, message: 'Lütfen şifrenizi giriniz!' }]}
        >
          <Input disabled={isVerified} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="success"
            block
            onClick={() => onVerify(form)}
            disabled={isVerified}
          >
            <VerifiedOutlined />
            Doğrula
          </Button>
        </Form.Item>
        <Form.Item
          label="SMS Başlığı"
          name="originator"
          rules={[{ required: true, message: 'Lütfen sağlayıcı seçiniz.' }]}
        >
          <Select disabled={!isVerified} options={config.originators} >
          </Select>
        </Form.Item>
        <Form.Item
          label="Kullanıcı Mobil"
          name="mobile"
          rules={[
            { required: true, message: 'Lütfen telefon numaranızı giriniz!' },
            { min: 10, max:10, message: 'Telefon numaranız başında 0 olmadan 10 hane olmalıdır.' },
            ]}
        >
          <Input addonBefore={
            <Form.Item name="prefix" noStyle>
              <Select
                disabled={!isVerified}
                style={{
                  width: 70,
                }}
              >
                <Option value="90">+90</Option>
              </Select>
            </Form.Item>
          }
                 disabled={!isVerified} />
        </Form.Item>
        <Form.Item name="credit" label="Kredi Miktarı">
          <span style={{ fontWeight: 'bold' }} className="ant-form-text">{isVerified || config.credit > 0 ? config.credit : 'Bilinmiyor'}</span>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConfigModalView;
