import React, { useState } from 'react';
import {Alert, Card, Layout, Typography, Input, Space, Dropdown, Menu} from 'antd';
import Button from 'antd/lib/button';
import {VideoCameraOutlined, StopOutlined, DownOutlined, LinkOutlined, SaveOutlined} from '@ant-design/icons';

const { Content, Footer } = Layout;
const { Title } = Typography;

const ActiveMeetingPanelView = ({ activeMeeting, startMeeting, endMeeting, activeMeetingUrl, updateMeetingUrl }) => {
    const [meetingUrl, setMeetingUrl] = useState(activeMeetingUrl);
    const [urlChanged, setUrlChanged] = useState(false);
    const providerLinks = ['https://teams.microsoft.com', 'https://zoom.us/meeting/schedule', 'https://meet.google.com'];
    const providerMenu = (
        <Menu onClick={e => {
            window.open(providerLinks[parseInt(e.key)], '_blank');
        }}>
            <Menu.Item key="0" icon={<LinkOutlined />}>
                Microsoft Teams
            </Menu.Item>
            <Menu.Item key="1" icon={<LinkOutlined />}>
                Zoom
            </Menu.Item>
            <Menu.Item key="2" icon={<LinkOutlined />}>
                Google Meet
            </Menu.Item>
        </Menu>
    );

    return (
        <Card
            bordered={false}
            title="Aktif Görüşme"
            style={{ maxWidth: 800, minWidth: 600, textAlign: 'center'}}
            bodyStyle={{ height:'100%', display: 'flex', flexDirection: 'column', 'alignItems': 'center', justifyContent: 'center' }}
        >
            {!activeMeeting &&
                <Title type="warning" code level={3}>AKTİF GÖRÜŞMENİZ BULUNMAMAKTADIR.</Title>
            }
            {activeMeeting && (activeMeeting.state === 1 || activeMeeting.state === 2) &&
                <div>
                    <Title type="warning" code level={3}>GÖRÜŞÜLECEK KİŞİ: {activeMeeting.nameSurname}</Title>
                    <Title type="warning" code level={3}>TEL: {activeMeeting.mobile}</Title>
                    {activeMeeting && activeMeeting.state === 2 &&
                    <Alert
                        style={{ marginTop: 50, marginBottom: 50}}
                        className="blink"
                        message="Görüşmeniz başladı."
                        description="Canlı görüşmeniz devam ediyor..."
                        type="warning"
                        showIcon
                    />
                    }
                    <div style={{ marginTop: 50, display: 'inline-grid', textAlign: 'center' }}>
                        { activeMeeting && activeMeeting.state === 1 &&
                        <Space>
                            <Dropdown overlay={providerMenu}>
                                <Button>
                                    Sağlayıcı <DownOutlined />
                                </Button>
                            </Dropdown>
                            <Input placeholder="Toplantı linki" style={{ minWidth: 300 }} value={meetingUrl} onChange={({ target: { value } }) => {
                                setUrlChanged(true);
                                setMeetingUrl(value);
                            }}/>
                            <Button
                                type="success"
                                onClick={() => {updateMeetingUrl(meetingUrl); setUrlChanged(false)}}
                            >
                                <SaveOutlined />
                            </Button>
                        </Space>
                        }
                    <Button
                        type="success"
                        style={{ marginTop: 10 }}
                        size="large"
                        shape="round"
                        onClick={startMeeting}
                        disabled={urlChanged}
                    >
                        <VideoCameraOutlined />
                        {activeMeeting.state === 1 ? 'Canlı Görüşmeyi Başlat' : 'Canlı Görüşmeye Git'}
                    </Button>
                    {activeMeeting && activeMeeting.state === 2 &&
                        <Button
                            type="danger"
                            style={{ width: '300px', marginTop: 20}}
                            size="large"
                            shape="round"
                            onClick={endMeeting}
                        >
                            <StopOutlined />
                            Görüşmeyi Sonlandır
                        </Button>
                    }
                    </div>
                </div>
            }
        </Card>
    );
};

export default ActiveMeetingPanelView;
