import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import CustomerRegisterView from './CustomerRegisterView';
import {loginAsCustomer, setBranchList, setCityList, setClassLevelList} from '../../reducers/app';

const CustomerRegister = ({}) => {
  const [cityCode, setCityCode] = useState(null);
  const { userType, cityList, classLevelList } = useSelector(state => state.app);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isRegisterPending, setIsRegisterPending] = useState(false);
  const param = new URLSearchParams(useLocation().search);
  const parBranchId = parseInt(param.get('id'));
  const parCityCode = param.get('city');
  const parSchoolType = param.get('schoolType');

  useEffect(() => {
    if (parCityCode) {
      setCityCode(parCityCode);
    }
  }, [parCityCode]);

  const branchList = useSelector(state => {
    let list;

    let code = cityCode || parCityCode;
    if (code) {
      list = state.app.branchList.filter(item => item.cityCode === code);
    } else {
      list = state.app.branchList;
    }

    if (parSchoolType) {
      list = list.filter(item => item.schoolCode === parseInt(parSchoolType));
    }
    return list;
  });

  useEffect(() => {
    if (userType === '') {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (branchList.length === 0) {
      axios.get('/api/guest-action.php', { params: { action: 'get-branch-list' } })
          .then(response => {
            if (response.data.success) {
              dispatch(setCityList(response.data.cityList));
              dispatch(setBranchList(response.data.branchList));
              dispatch(setClassLevelList(response.data.classLevelList));
            }
          });
    }
  }, []);

  const onFinish = (values) => {
    setIsRegisterPending(true);

    axios.post('/api/customer-action.php', { action: 'register-customer', ...values })
        .then(response => {
          if (response.data.success) {
            dispatch(loginAsCustomer(response.data.user));
            setTimeout(() => {
              history.push('/');
            }, 50);
          } else {
            setIsRegisterPending(false)
          }
        }).catch(() => setIsRegisterPending(false));
  };

  return (
    <CustomerRegisterView
      cityList={cityList}
      branchList={branchList}
      classLevelList={classLevelList}
      cityCode={parCityCode}
      branchId={parBranchId}
      onFinish={onFinish}
      isRegisterPending={isRegisterPending}
      setCityCode={setCityCode}
    />
  )
};

export default CustomerRegister;
