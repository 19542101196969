import { configureStore } from '@reduxjs/toolkit'
import appReducer from './reducers/app';
import meetingReducer from './reducers/meeting';

export default configureStore({
  reducer: {
    app: appReducer,
    meeting: meetingReducer
  },
});
